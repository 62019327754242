import React from 'react';
import { Link } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import { useAuthUser } from '@hala-systems/hala-auth';
import { useLocale, useTranslation } from '@hala-systems/translation-service';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import { Select, IconButton, Grid, Menu, MenuItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  AttrIconLarge,
  MenuVerticalIcon,
  BritishFlag,
  YemenFlag,
} from 'styles/assets/icons';
import { AboutModal } from 'components/pages/About';
import { UserSettingsModal } from 'components/pages/UserSettings';
import { useStyles, MenuToolbar, Heading } from './NavBarStyles';
import { userHasPermission } from 'auth/permissions';

export const NavBar = ({ showCloseIcon = false }) => {
  const { user, loading } = useAuthUser();
  const { locale, setLocale } = useLocale();
  const translate = useTranslation();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openModal, setOpenModal] = React.useState(false);
  const [openSettingsModal, setOpenSettingsModal] = React.useState(false);

  const languageOptions = [
    { language: 'en', icon: <BritishFlag /> },
    { language: 'ar', icon: <YemenFlag /> },
  ];

  const HomeIcon = showCloseIcon ? CloseIcon : AttrIconLarge;

  const handleClick = ({ currentTarget }) => {
    setAnchorEl(currentTarget);
  };
  const handleClose = () => setAnchorEl(null);

  const handleOpenModal = () => {
    setOpenModal(true);
    handleClose();
  };

  const handleOpenSettingsModal = () => {
    setOpenSettingsModal(true);
    handleClose();
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleCloseSettingsModal = () => {
    setOpenSettingsModal(false);
  };

  const logout = (event) => {
    Auth.signOut();
    setAnchorEl(null);
  };

  const myRef = React.createRef();
  return (
    <MenuToolbar variant="dense">
      <AboutModal
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        handleOpenModal={handleOpenModal}
      />
      <UserSettingsModal
        openModal={openSettingsModal}
        handleCloseModal={handleCloseSettingsModal}
        handleOpenModal={handleOpenSettingsModal}
      />
      <Link to="/">
        <IconButton>
          <HomeIcon className={classes.icon} />
        </IconButton>
      </Link>
      <Grid container direction="row" justify="flex-end" alignItems="center">
        <Heading variant="h6">{translate('title', 'attr.')}</Heading>
        <Select
          role="select"
          aria-label="open language-menu"
          name="language"
          value={locale}
          IconComponent={ArrowDropDownIcon}
          onChange={(value) => {
            setLocale(value.target.value);
          }}
          id="language"
          inputProps={{
            classes: {
              icon: classes.icon,
            },
          }}
        >
          {languageOptions.map(({ language, icon }) => {
            return (
              <MenuItem key={language} value={language} name={language}>
                {icon}
              </MenuItem>
            );
          })}
        </Select>
        <IconButton
          edge="end"
          aria-controls="user-menu"
          aria-label="open user-menu"
          aria-haspopup="true"
          onClick={handleClick}
          className={classes.hamburger}
        >
          <MenuVerticalIcon />
        </IconButton>
        <Menu
          id="user-menu"
          getContentAnchorEl={null}
          anchorEl={anchorEl}
          ref={myRef}
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        >
          {userHasPermission(user, 'ACCESS_ATTRIBUTE') && (
            <MenuItem component={Link} to="/" onClick={handleClose}>
              {translate('postList', 'Post List')}
            </MenuItem>
          )}
          {userHasPermission(user, 'CREATE_POST_LABEL') && (
            <MenuItem
              component={Link}
              to="/add-facebook-post"
              onClick={handleClose}
            >
              {translate('addFacebookPost', 'Add Facebook Post')}
            </MenuItem>
          )}
          <MenuItem onClick={handleOpenModal}>
            {translate('about', 'About')}
          </MenuItem>
          <MenuItem onClick={handleOpenSettingsModal}>
            {translate('languageSettings', 'Language Settings')}
          </MenuItem>
          <MenuItem
            component="a"
            href="mailto:attribute@halasystems.com"
            target="_blank"
            onClick={handleClose}
          >
            {translate('feedback', 'Send Feedback')}
          </MenuItem>
          {!user & !loading ? (
            <MenuItem onClick={() => Auth.federatedSignIn()}>
              {translate('signIn', 'Sign In')}
            </MenuItem>
          ) : (
            <MenuItem onClick={logout}>
              {translate('logout', 'Logout')}
            </MenuItem>
          )}
        </Menu>
      </Grid>
    </MenuToolbar>
  );
};
