import React from 'react';
import { AmplifySignUp } from '@aws-amplify/ui-react';
import { HalaAuthenticator } from '@hala-systems/hala-auth';
import { initAmplify } from 'services/amplify';

initAmplify();

export const AttributeAuthenticator = ({ children }) => {
  return (
    <HalaAuthenticator usernameAlias="email">
      <AmplifySignUp
        slot="sign-up"
        usernameAlias="email"
        formFields={[
          {
            type: 'email',
            label: 'Email',
            placeholder: 'email address',
            required: true,
          },
          {
            type: 'password',
            label: 'Password',
            placeholder: 'password',
            required: true,
          },
        ]}
      />
      {children}
    </HalaAuthenticator>
  );
};
