import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from '@hala-systems/translation-service';
import _ from 'lodash';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';

import { getOffsetString } from './utils';
import { useInputLabelStyles, useSelectStyles } from '../../FormStyles';
/**
 * Displays a select component with options for the theater time
 * or UTC time or the users local time
 *
 * The selected timezone is passed to the setTimeZone callback
 * as an IANA string.
 *
 * @param {*} param0
 */
const TimeZoneSelect = ({ theater, value, setTimezone, date }) => {
  const selectClasses = useSelectStyles();
  const inputLabelclasses = useInputLabelStyles();
  const translate = useTranslation();
  const localTz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  useEffect(() => {
    setTimezone(getTheaterTimezone(theater) ?? localTz);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [theater]);

  const localOffsetString = getOffsetString(localTz, date);

  const options = [
    { value: 'Etc/UTC', label: `${translate('utc')}` },
    {
      value: localTz,
      label: `${translate('localTimeZone')} (${localOffsetString})`,
    },
  ];

  const theaterTz = getTheaterTimezone(theater);

  if (theaterTz) {
    const theaterOffsetString = getOffsetString(theaterTz, date);
    options.unshift({
      value: theaterTz,
      label: `${_.startCase(
        _.lowerCase(translate(_.lowerCase(theater)))
      )} (${theaterOffsetString})`,
    });
  }

  return (
    <FormControl fullWidth>
      <InputLabel id="tz-select-label" classes={inputLabelclasses}>
        {translate('incidentTimezone')}
      </InputLabel>
      <Select
        labelId="tz-select-label"
        id="tz-select"
        classes={selectClasses}
        data-testid="tz-select"
        value={value ?? ''}
        onChange={(e) => setTimezone(e.target.value)}
        inputProps={{
          'data-testid': 'tz-select-input',
        }}
      >
        {options.map((o) => (
          <MenuItem key={o.value} value={o.value}>
            {o.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const getTheaterTimezone = (theater) => {
  const timeZoneMap = {
    YEMEN: 'Asia/Aden',
    SYRIA: 'Asia/Damascus',
  };

  // If the theater is valid, return the short timezone
  return theater in timeZoneMap ? timeZoneMap[_.upperCase(theater)] : null;
};

TimeZoneSelect.propTypes = {
  // The currently selected theater
  theater: PropTypes.string,
  // The current value of the timezone, IANA String
  value: PropTypes.string,
  // A function to update the timezone
  setTimezone: PropTypes.func.isRequired,
  // An optional `Date` to inform the timezone offset for DST
  // If null, the current date will be used
  date: PropTypes.instanceOf(Date),
};

export default TimeZoneSelect;
