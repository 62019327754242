import { Auth } from 'aws-amplify';
import { isHalaDomain } from './utils';

export const IDENTITY_POOL = process.env.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID;
export const REGION = process.env.REACT_APP_AWS_PROJECT_REGION;
const USER_POOL_ID = process.env.REACT_APP_AWS_USER_POOL_ID;
const CLIENT_ID = process.env.REACT_APP_AWS_USER_POOLS_WEB_CLIENT_ID;
const REDIRECT_SIGNIN = process.env.REACT_APP_LOGIN_CALLBACK_URL;
const REDIRECT_SIGNOUT = process.env.REACT_APP_LOGOUT_CALLBACK_URL;

const amplifyConfig = {
  Auth: {
    // identityPoolId: IDENTITY_POOL,
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: CLIENT_ID,
    ...(isHalaDomain() && {
      cookieStorage: {
        domain: '.halasystems.com',
        path: '/',
        expires: 3,
        sameSite: 'none',
        secure: true,
      },
    }),
    oauth: {
      // domain: 'blue-java-prd.auth.us-east-1.amazoncognito.com/',
      scope: ['email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: REDIRECT_SIGNIN,
      redirectSignOut: REDIRECT_SIGNOUT,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  },
};
export const initAmplify = () => {
  Auth.configure(amplifyConfig);
};
