import * as Sentry from '@sentry/react';
import get from 'lodash/get';

/**
 * Checks that a user has a certain permission
 *
 * @param {Object} user The currently logged in user
 * @param {String} permissionName A permission to check for
 */

export const userHasPermission = (user, permissionName) => {
  if (!user) return false;
  try {
    const permissions = getUserPermissions(user);
    return permissions.includes(permissionName);
  } catch (err) {
    Sentry.captureMessage('User permission authorization failed', { user });
    return false;
  }
};

/**
 *
 * @param {object} user The currently logged in user
 * @param {string} roleName a role to check
 * @returns a boolean indicating whether user has the role or not
 */
export const userHasRole = (user, roleName) => {
  if (!user) return false;
  try {
    const roles = getUserRoles(user);
    return roles.includes(roleName.toLowerCase());
  } catch (err) {
    Sentry.captureMessage('User roles authorization failed', { user });
    return false;
  }
};

/**
 *
 * @param {object} user the currently logged in user
 * @param {string} regionName the region a user belongs to
 * @returns a boolean indicating whether user belongs to region or not
 */
export const userHasRegion = (user, regionName) => {
  if (!user) return false;
  try {
    const regions = getUserRegions(user);
    return regions.includes(regionName.toLowerCase());
  } catch (err) {
    Sentry.captureMessage('User regions authorization failed', { user });
    return false;
  }
};

export const getUserPermissions = (user) => {
  return user?.signInUserSession.idToken.payload['permissions'];
};

const getUserConfigs = (user) => {
  const userConfigs = JSON.parse(
    get(user, 'signInUserSession.idToken.payload.configs', '{}')
  );
  return userConfigs;
};

export const getUserRoles = (user) => {
  const roles = getUserConfigs(user)?.roles;

  if (!roles) return '';
  return roles.map((role) => `role:${role.toLowerCase()}`);
};

export const getUserRegions = (user) => {
  const regions = getUserConfigs(user)?.regions;
  if (!regions) return '';
  return regions.map((region) => `region:${region.toLowerCase()}`);
};
