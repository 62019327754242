import React, { useContext } from 'react';
import createPersistedState from 'use-persisted-state';

// Local storage for users fluent languages. This is sent with query for postList and user only receives post in languages they know
const useFluentLanguagesPersist = createPersistedState('attr-fluentLanguages');

const FluentLanguagesContext = React.createContext({});

export const FluentLanguagesProvider = ({ children }) => {
  const [fluentLanguages, setFluentLanguages] = useFluentLanguagesPersist([]); // default of no languages set. If no languages are sent, you will receive all posts.

  return (
    <FluentLanguagesContext.Provider
      value={{ fluentLanguages, setFluentLanguages }}
    >
      {children}
    </FluentLanguagesContext.Provider>
  );
};

export const useFluentLanguages = () => useContext(FluentLanguagesContext);
