import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { useTranslation } from '@hala-systems/translation-service';

// TODO this is a placeholder so we could submit a page for google auth
export const PrivacyPolicy = () => {
  const translate = useTranslation();
  return (
    <Box p={6}>
      <Typography variant="h3" component="h1">
        {translate('privacyPolicy', 'Privacy Policy')}
      </Typography>
    </Box>
  );
};
