import React from 'react';
import { PostListItem } from 'components/pages/PostList/PostListItem';
import { Refresh } from 'styles/assets/icons';
import { Box, CircularProgress, List, IconButton } from '@material-ui/core';
import { useTranslation } from '@hala-systems/translation-service';

export function PostList({
  postList,
  refetch,
  refetching,
  postsSelected,
  setPostsSelected,
}) {
  const translate = useTranslation();
  return (
    <Box px={{ xs: 1, md: 5 }}>
      {postList[0] || refetching ? (
        <>
          <List>
            {postList.map((post) => {
              return (
                <PostListItem
                  key={post.id}
                  post={post}
                  postsSelected={postsSelected}
                  setPostsSelected={setPostsSelected}
                />
              );
            })}
          </List>
          <Box p={2} display="flex" justifyContent="center">
            {!refetching ? (
              <IconButton onClick={() => refetch()}>
                <Refresh />
              </IconButton>
            ) : (
              <CircularProgress size={48} />
            )}
          </Box>
        </>
      ) : (
        <p>
          {translate(
            'noPostsLeft',
            "We don't currently have any posts for you to review"
          )}
        </p>
      )}
    </Box>
  );
}
