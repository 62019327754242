import React from 'react';
import { ApolloProvider } from '@apollo/client';
import LocaleProvider from '@hala-systems/translation-service';
import { FluentLanguagesProvider } from 'providers/FluentLanguagesProvider';
import { BrowserRouter as Router } from 'react-router-dom';
import 'App.css';
import Routes from 'Routes';
import { BlueJavaClient } from 'services/apollo';
import BlueJavaThemeProvider from 'styles/ThemeProvider';
import QuestionsProvider from 'components/QuestionsProvider';
import * as messages from './translations';

const App = () => {
  return (
    <LocaleProvider extendedMessages={messages}>
      <FluentLanguagesProvider>
        <ApolloProvider client={BlueJavaClient}>
          <BlueJavaThemeProvider>
            <QuestionsProvider>
              <Router>
                <Routes />
              </Router>
            </QuestionsProvider>
          </BlueJavaThemeProvider>
        </ApolloProvider>
      </FluentLanguagesProvider>
    </LocaleProvider>
  );
};

export default App;
