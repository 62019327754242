import React, { useState, useEffect } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import { determineLocationText } from './utils';
import { useTranslation } from '@hala-systems/translation-service';
import isEqual from 'lodash/isEqual';
import { useDebounce } from 'hooks/useDebounce';

const LocationSearch = ({
  locationOptions,
  loading,
  onLocationsChange,
  onDebouncedSearchTermChange,
  chosenLocations,
  isRequired,
  theater,
}) => {
  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const translate = useTranslation();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const defaultOptionsLocation = [
    {
      location: translate('typeToSearch'),
    },

    {
      location: translate('locationUnknown'),
      adminLevel0: translate(theater.toLowerCase()),
      unknown: true,
    },
  ];

  let isMulti =
    locationOptions.length > 0 && locationOptions[0].unknown === undefined
      ? true
      : false;

  useEffect(() => {
    onDebouncedSearchTermChange(debouncedSearchTerm);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchTerm]);

  return (
    <>
      <Autocomplete
        data-testid="location-search"
        filterSelectedOptions
        fullWidth
        multiple={isMulti}
        open={open}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        noOptionsText={translate('noOptions')}
        loadingText={translate('loading')}
        includeInputInList
        value={isMulti ? chosenLocations : chosenLocations[0] || null}
        onChange={(e, value) => {
          if (value === null) {
            value = [];
          } else if (!isMulti) {
            value = [value];
          }
          onLocationsChange(value);
          setSearchTerm('');
        }}
        getOptionSelected={(option, value) => {
          if (!isMulti) {
            return isEqual(option.location, value.location);
          } else {
            return isEqual(option, value);
          }
        }}
        getOptionLabel={(option) => determineLocationText(option)}
        options={isMulti ? locationOptions : defaultOptionsLocation}
        getOptionDisabled={(option) => option === defaultOptionsLocation[0]}
        loading={loading}
        ChipProps={{ size: 'small' }}
        renderInput={(params) => (
          <TextField
            fullWidth
            {...params}
            required={isRequired}
            helperText={isRequired ? '*Required' : null}
            label={translate('locationHeader')}
            onChange={(e) => setSearchTerm(e.target.value)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <>
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </>
              ),
            }}
          />
        )}
      />
    </>
  );
};

export default LocationSearch;
