import React from 'react';
import { useTranslation } from '@hala-systems/translation-service';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Button from '@material-ui/core/Button';
import Fade from '@material-ui/core/Fade';
import { useStyles } from './AboutStyles';
import PropTypes from 'prop-types';

export const AboutModal = ({ openModal, handleCloseModal }) => {
  const translate = useTranslation();
  const classes = useStyles();
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.paper}>
            <h4 className={classes.title}>Attribute (attr.)</h4>
            <h4
              className={classes.buildNo}
            >{`BUILD v${process.env.REACT_APP_VERSION}`}</h4>
            <h4 className={classes.date}>
              {process.env.REACT_APP_DATE ? process.env.REACT_APP_DATE : '-'}
            </h4>
            <h4 className={classes.text}>Developed by Hala Systems</h4>
            <div className={classes.buttonSection}>
              <Button
                variant="text"
                href="mailto:attribute@halasystems.com"
                target="_blank"
              >
                {translate('help', 'HELP')}
              </Button>
              <Button variant="text" onClick={handleCloseModal}>
                {translate('close', 'CLOSE')}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

AboutModal.propTypes = {
  openModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func,
};
