const BLUE_JAVA_PWA_DOMAINS = [
  'https://attr.halasystems.com',
  'https://attr-staging.halasystems.com',
];

const getCurrentOrigin = () => {
  const origin = window.location.origin;
  return origin;
};
export const isHalaDomain = () => {
  const origin = getCurrentOrigin();
  return BLUE_JAVA_PWA_DOMAINS.includes(origin);
};
