import React from 'react';
import { Formik } from 'formik';
import { useTranslation } from '@hala-systems/translation-service';
import {
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';

import { StyledFab } from './FacebookFormStyles';
import { Save } from 'styles/assets/icons';
import { DateTimeInput } from 'components/helpers';

export const FacebookForm = ({ handleFacebookPost }) => {
  const translate = useTranslation();

  return (
    <Box px={5} py={3} width={{ xs: '100vw', md: '50vw' }}>
      <Formik
        enableReinitialize
        initialValues={{
          title: '',
          created: '',
          recordUrl: '',
          sourceName: '',
          language: '',
          content: '',
          sourceType: '',
          sourceUrl: '',
        }}
        validate={(values) => {
          const errors = {};
          if (
            !values.created ||
            Number.isNaN(new Date(values.created).getTime()) // check for if valid date
          ) {
            errors.created = translate('createDateTimeError');
          }
          if (!values.sourceUrl) {
            // TODO - This should validate the URL format
            errors.sourceUrl = translate('sourceUrlError');
          }
          if (!values.recordUrl) {
            errors.recordUrl = translate('recordUrlError');
          }
          if (!values.sourceName) {
            errors.sourceName = translate('sourceNameError');
          }
          if (!values.content) {
            errors.content = translate('contentError');
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting, resetForm, setFieldValue }) => {
          try {
            setSubmitting(true);
            const post = {
              created: values.created,
              recordType: 'post',
              language: values.language,
              content: values.content,
              fetchedTime: new Date().toISOString(),
              sourceName: values.sourceName,
              sourceType: values.sourceType,
              sourceUrl: values.sourceUrl,
              recordUrl: values.recordUrl,
            };
            handleFacebookPost(post);
          } catch (e) {
            setSubmitting(false);
          }
          resetForm();
          setSubmitting(false);
          setFieldValue('created', '');
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleSubmit,
          handleBlur,
          isSubmitting,
          setFieldValue,
        }) => {
          const setCreatedDate = (date) =>
            setFieldValue('created', date?.toISOString());
          const createdDate = values.created ? values.created : null;

          return (
            <form onSubmit={handleSubmit}>
              <DateTimeInput
                name="created"
                value={createdDate}
                onChange={setCreatedDate}
                dateLabel={translate('createDate')}
                timeLabel={translate('createTime')}
                error={
                  errors.created && touched.created ? errors.created : null
                }
              />
              <TextField
                fullWidth
                required
                id="recordUrl"
                aria-label="recordUrl"
                label={translate('postUrl')}
                value={values.recordUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.recordUrl && touched.recordUrl}
                helperText={touched.recordUrl && errors.recordUrl}
              />
              <FormControl fullWidth>
                <InputLabel htmlFor="language">
                  {translate('language')}
                </InputLabel>
                <Select
                  name="language"
                  value={values.language}
                  onChange={handleChange}
                  onBlur={(event) => {
                    event.target.name = 'language';
                    handleBlur(event);
                  }}
                  id="language"
                >
                  <MenuItem value="en">{translate('english')}</MenuItem>
                  <MenuItem value="ar">{translate('arabic')}</MenuItem>
                </Select>
              </FormControl>

              <TextField
                fullWidth
                required
                id="sourceName"
                aria-label="sourceName"
                label={translate('sourceName')}
                value={values.sourceName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.sourceName && touched.sourceName}
                helperText={touched.sourceName && errors.sourceName}
              />
              <TextField
                fullWidth
                required
                id="sourceUrl"
                aria-label="sourceUrl"
                label={translate('sourceUrl')}
                value={values.sourceUrl}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.sourceUrl && touched.sourceUrl}
                helperText={touched.sourceUrl && errors.sourceUrl}
              />

              <FormControl fullWidth>
                <InputLabel htmlFor="sourceType">
                  {translate('sourceType')}
                </InputLabel>
                <Select
                  value={values.sourceType}
                  name="sourceType"
                  onChange={handleChange}
                  onBlur={(event) => {
                    event.target.name = 'sourceType';
                    handleBlur(event);
                  }}
                  id="sourceType"
                  label={translate('sourceType')}
                >
                  <MenuItem key="individual" value="individual">
                    {translate('individual')}
                  </MenuItem>
                  <MenuItem key="group" value="group">
                    {translate('group')}
                  </MenuItem>
                  <MenuItem key="event" value="event">
                    {translate('event')}
                  </MenuItem>
                </Select>
              </FormControl>
              <TextField
                fullWidth
                required
                id="content"
                aria-label="content"
                label={translate('content')}
                multiline
                value={values.content}
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.content && touched.content}
                helperText={touched.content && errors.content}
              />
              <br />
              <br />
              <p>{translate('requiredField')}</p>

              <StyledFab
                disabled={isSubmitting}
                type="button"
                id="submit-form"
                aria-label="submit-form"
                variant="round"
                onClick={handleSubmit}
              >
                {isSubmitting ? <CircularProgress /> : <Save />}
              </StyledFab>
            </form>
          );
        }}
      </Formik>
    </Box>
  );
};
