import React from 'react';
import { useLocale, useTranslation } from '@hala-systems/translation-service';
import { DatePicker } from '@material-ui/pickers';
import TextField from '@material-ui/core/TextField';

import TimeZoneSelect from './TimeZoneSelect';
import { getIncidentTimeString } from './utils';
import { LocaleMuiPickersUtilsProvider } from 'components/helpers';

/**
 * `incidentTime` have two props
 * `dateString` and `parts`. Parts are retained in state to make it
 * easy to update this component when it re-renders
 *
 * `{parts: {date, time, timezone}, dateString}`
 *
 * `incidentTime` can be submitted with or without a time,
 * but must conform to ISO 8601 formatting, so although we
 * want to include timezone, that will not be sent unless
 * the time value is set.
 *
 * So, valid `incidentTime` strings are:
 *
 * No data: `null`
 * Date only: `YYYY-MM-DD`
 * Date and time: `YYYY-MM-DD hh:mm±hh:mm`
 *
 * A timezone is always selected in the interface. It defaults to
 * the theater if the theater is set, otherwise it defaults
 * to the user's local time, but they also have the option
 * of UTC
 */
export default function DateTimeQuestion({ incidentTime, theater, onChange }) {
  const { locale } = useLocale();
  const translate = useTranslation();

  const parts = incidentTime?.parts ?? {
    date: null,
    time: '',
    timezone: null,
  };

  // Updates `parts` and `dateString` attributes of `incidentTime`
  // Transforms the date/time/tz values in `parts` to a ISO string
  // and stores that in the `dateString` attribute
  const updateIncidentTime = (changes) => {
    const newParts = { ...parts, ...changes };
    const dateString = getIncidentTimeString(newParts);

    onChange({ dateString, parts: newParts });
  };

  const updateTimezone = (timezone) => updateIncidentTime({ timezone });
  const updateTime = (time) => updateIncidentTime({ time });
  const updateDate = (date) => {
    // clear the time when clearing the date
    const time = date ? parts.time : '';
    updateIncidentTime({ date, time });
  };

  // Shared *Picker props
  const pickerProps = {
    fullWidth: true,
    clearable: true,
    autoOk: true,
    okLabel: translate('set'),
    cancelLabel: translate('cancel'),
    clearLabel: translate('clear'),
  };

  const { date, time, timezone } = parts;

  return (
    <LocaleMuiPickersUtilsProvider locale={locale}>
      <TimeZoneSelect
        theater={theater}
        value={timezone}
        setTimezone={updateTimezone}
        date={date}
      />

      <DatePicker
        {...pickerProps}
        id="date-input-question"
        aria-label="date-input"
        format="yyyy-MM-dd"
        value={date}
        onChange={updateDate}
        label={translate('incidentDate')}
        disableFuture
      />
      <TextField
        fullWidth
        InputLabelProps={{
          shrink: true,
        }}
        id="time-input-question"
        aria-label="time-input"
        type="time"
        value={time}
        onChange={(e) => updateTime(e.target.value)}
        disabled={date === null}
        label={translate('incidentTime')}
      />
    </LocaleMuiPickersUtilsProvider>
  );
}
