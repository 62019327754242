import React, { useMemo } from 'react';
import _ from 'lodash';
import { useTranslation } from '@hala-systems/translation-service';
import { useAuthUser } from '@hala-systems/hala-auth';
import configManagementService from '@hala-systems/config-mngt-service';

import {
  userHasRole,
  getUserRoles,
  userHasRegion,
  getUserRegions,
} from 'auth/permissions';

import { RELEVANCE_OPTIONS } from 'constants/index';
import QuestionsContext from './QuestionsContext';

export default function QuestionsProvider({ children }) {
  const translate = useTranslation();
  const { user } = useAuthUser();

  const INCIDENT_TYPES = useMemo(() => {
    const incidentTypesCriteria = getIncidentTypesCriteria(user);
    return configManagementService.getIncidentTypeList({
      filterRules: incidentTypesCriteria,
      labelRules: incidentTypesCriteria,
    });
  }, [user]);

  const THEATER_OPTIONS = useMemo(() => {
    const theaterCriteria = getTheaterCriteria(user);
    return configManagementService.getTheaterList({
      filterRules: theaterCriteria,
      labelRules: theaterCriteria,
    });
  }, [user]);

  const questions = useMemo(
    () => ({
      relevance: {
        progress: 1,
        header: translate('relevanceHeader'),
        dropdown: {
          text: translate('relevanceLabel'),
          options: translateList(translate, RELEVANCE_OPTIONS, {
            translateKey: 'translateId',
          }),
        },
      },
      incidents: {
        progress: 2,
        header: translate('incidentReport'),
        items: {
          geographic: {
            dropdown: {
              text: translate('theaterLabel'),
              options: translateList(translate, THEATER_OPTIONS, {
                translateKey: 'name',
              }),
            },
          },
          incidentType: {
            dropdown: {
              text: translate('incidentHeader'),
              options: translateList(translate, INCIDENT_TYPES, {
                translateKey: 'name',
                namespace: 'incidentTypes',
              }),
            },
          },
        },
      },
    }),
    [translate, THEATER_OPTIONS, INCIDENT_TYPES]
  );

  return (
    <QuestionsContext.Provider value={questions}>
      {children}
    </QuestionsContext.Provider>
  );
}

const translateList = (translate, list, { translateKey, namespace }) => {
  return _.chain(list)
    .map((item) => ({
      id: item.id,
      text: translate(
        `${namespace ? namespace + '.' : ''}${item[translateKey]}`
      ),
    }))
    .orderBy((item) => item.text)
    .value();
};

const getIncidentTypesCriteria = (user) => {
  let incidentTypesCriteria = 'role:data-labeler';
  if (
    userHasRole(user, 'role:observer') ||
    userHasRole(user, 'role:data-labeler')
  ) {
    incidentTypesCriteria = getUserRoles(user)?.join();
  }
  return incidentTypesCriteria;
};

const getTheaterCriteria = (user) => {
  let theaterCriteria = 'region:yemen';
  if (
    userHasRegion(user, 'region:yemen') ||
    userHasRegion(user, 'region:syria')
  ) {
    theaterCriteria = getUserRegions(user)?.join();
  }
  return theaterCriteria;
};
