import React, { useReducer, useState } from 'react';
import _ from 'lodash';
import { useHistory } from 'react-router-dom';
import { useTranslation } from '@hala-systems/translation-service';

import Toaster from 'utils/toasterConfig';
import Post from './Post';
import SwipeableBottomDrawer from '../../layout/SwipeableBottomDrawer';
import WizardBottomControls from './WizardBottomControls';
import IncidentHeader from './IncidentHeader';
import WizardContent from './WizardContent';
import { useQuestions } from 'components/QuestionsProvider';

import { useStyles } from './AnalyzePostPageStyles';
import { usePostMutation } from 'hooks/usePostMutation/usePostMutation';
import { isNotRelevant } from './utils';

import {
  addIncident,
  removeIncident,
  updateIncident,
} from './store/actions/incidentActions';
import incidentReducer from './store/reducers/incidentReducer';

export default function AnalyzePostPage({ location }) {
  const translate = useTranslation();
  const post = location?.state?.post; // post state is created through the link clicked to={{ pathname: `/post/${post.id}`, state: { post } }}
  const history = useHistory();
  const classes = useStyles();
  const { questions } = useQuestions();
  const [relevanceAnswer, setRelevanceAnswer] = useState('');
  const [currentStep, setCurrentStep] = useState(questions.relevance);
  const { postMutation, loading } = usePostMutation();

  const BRAND_NEW_INCIDENT = {
    theater: questions.incidents.items.geographic.dropdown.options[0].id,
    incidentType: [],
    locations: [],
    incidentTime: null,
    geographicVerifications: null,
  };

  const [incidents, incidentsDispatch] = useReducer(incidentReducer, [
    BRAND_NEW_INCIDENT,
  ]);

  const totalSteps = isNotRelevant(relevanceAnswer)
    ? 1
    : Object.keys(questions).length;

  if (!post) return null;

  return (
    <div className={classes.root}>
      <Post post={post} />
      <SwipeableBottomDrawer
        renderHeader={() => (
          <IncidentHeader
            label={currentStep.header}
            renderEndAdornment={() => `${currentStep.progress}/${totalSteps}`}
          />
        )}
        renderContent={() => (
          <WizardContent
            currentStep={currentStep}
            relevanceAnswer={relevanceAnswer}
            onRelevanceFormChange={handleRelevanceFormChange}
            incidents={incidents}
            onUpdateIncident={(index, updatedIncident) =>
              incidentsDispatch(updateIncident(index, updatedIncident))
            }
            onDeleteIncident={(index) => deleteIncident(index)}
            onAddNewIncident={addNewIncident}
          />
        )}
        renderFooter={() => (
          <WizardBottomControls
            currentStep={currentStep}
            relevanceAnswer={relevanceAnswer}
            loading={loading}
            onChangeCurrentStep={(step) => setCurrentStep(step)}
            canISaveIncidents={canISaveIncidents()}
            onSaveIncidents={submitIncidents}
          />
        )}
      />
    </div>
  );

  function handleRelevanceFormChange(answer) {
    setRelevanceAnswer(answer);
    const nextStep = {
      true: questions.relevance,
      false: questions.incidents,
    };

    setCurrentStep(nextStep[isNotRelevant(answer)]);
  }

  function canISaveIncidents() {
    return _.every(
      incidents,
      (incident) =>
        !_.isEmpty(incident.incidentType) && !_.isEmpty(incident.locations)
    );
  }

  function addNewIncident() {
    incidentsDispatch(addIncident(BRAND_NEW_INCIDENT));
  }

  function deleteIncident(index) {
    incidentsDispatch(removeIncident(index));
  }

  function submitIncidents() {
    let incidentsToSubmit = [
      {
        postId: post.id,
        relevant: relevanceAnswer,
      },
    ];

    if (!isNotRelevant(relevanceAnswer)) {
      incidentsToSubmit = incidents.map((incident) => ({
        postId: post.id,
        relevant: relevanceAnswer,
        ...incident,
      }));
    }

    postMutation(incidentsToSubmit)
      .then(() => {
        Toaster.success(translate('toasterSuccess'));
        history.push('/');
      })
      .catch(() => {
        Toaster.error(translate('toasterError'));
      });
  }
}
