import React from 'react';
import { useTranslation } from '@hala-systems/translation-service';
import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Add';

import { useButtonStyles } from '../FormStyles';
import { useStyles } from './AddIncidentButtonStyles';

export default function AddIncidentButton({ onClick = (f) => f }) {
  const buttonClasses = useButtonStyles();
  const addIncidentButtonClasses = useStyles();
  const translate = useTranslation();

  return (
    <Button
      onClick={onClick}
      variant={'outlined'}
      classes={{ ...buttonClasses, ...addIncidentButtonClasses }}
      startIcon={<AddIcon />}
    >
      {translate('addAnotherIncident')}
    </Button>
  );
}
