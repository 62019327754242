import React from 'react';
import { NetworkStatus, useQuery } from '@apollo/client';
import { useTranslation } from '@hala-systems/translation-service';
import { LinearProgress, Typography } from '@material-ui/core';
import { PostList } from 'components/pages/PostList';
import GET_POSTS from 'queries/getPosts';
import { useFluentLanguages } from 'providers/FluentLanguagesProvider';

export const PostListContainer = ({ postsSelected, setPostsSelected }) => {
  const translate = useTranslation();
  const { fluentLanguages } = useFluentLanguages();
  const { data, error, refetch, networkStatus } = useQuery(GET_POSTS, {
    notifyOnNetworkStatusChange: true,
    variables: { languages: fluentLanguages },
  });
  const loading = networkStatus === NetworkStatus.loading;
  const refetching =
    networkStatus === NetworkStatus.refetch ||
    networkStatus === NetworkStatus.setVariables;
  if (error)
    return (
      <>
        {translate(
          'pageError',
          'Error loading page. Please refresh and try again'
        )}
      </>
    );
  if (loading)
    return (
      <>
        <Typography variant="h5">
          {translate('fetchingPosts', 'Fetching posts....')}
        </Typography>
        <LinearProgress variant="query" />
      </>
    );

  const posts = data?.fetchPostsForLanguages ?? [];

  return (
    <PostList
      postList={posts}
      refetch={refetch}
      refetching={refetching}
      postsSelected={postsSelected}
      setPostsSelected={setPostsSelected}
    />
  );
};

// Code to support stubbed data locally

// import { stubbedPostList } from 'utils/stubbedData';
// let localPosts = false;
// if (
//   window.location.hostname === 'localhost' ||
//   window.location.hostname === '127.0.0.1'
// ) {
//   localPosts = stubbedPostList;
// }
// let posts = localPosts ? localPosts : postList;
