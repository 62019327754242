import React from 'react';
import { useTranslation } from '@hala-systems/translation-service';
import { create } from 'jss';
import rtl from 'jss-rtl';
import {
  MuiThemeProvider,
  StylesProvider,
  ThemeProvider,
  jssPreset,
} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import theme from 'styles/theme';

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });

const BlueJavaThemeProvider = ({ children }) => {
  const translate = useTranslation();
  React.useLayoutEffect(() => {
    document.body.setAttribute('dir', translate('direction'));
  }, [translate]);
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <MuiThemeProvider
          theme={{
            ...theme,
            direction: translate('direction'),
          }}
        >
          {children}
        </MuiThemeProvider>
      </ThemeProvider>
    </StylesProvider>
  );
};

export default BlueJavaThemeProvider;
