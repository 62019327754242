import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { useTranslation } from '@hala-systems/translation-service';

export default function FluentLanguagesDialog({
  openModal,
  handleCloseModal,
  children,
}) {
  const translate = useTranslation();
  return (
    <Dialog
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="lang-dialog-title"
    >
      <DialogTitle id="lang-dialog-title">
        {translate('fluentLanguages', 'Fluent Languages')}
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={handleCloseModal} color="primary">
          {translate('close', 'CLOSE')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
